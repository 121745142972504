export const ADMIN = '/admin/';
export const ADMIN_USERS = `${ADMIN}users`;
export const ADMIN_USERS_DETAILS = `${ADMIN}user-details/`;
export const ADMIN_USERS_DETAILS_ID = `${ADMIN_USERS_DETAILS}:dataUserId`;

export const ADMIN_CONVERSATIONS = `${ADMIN}conversations`;
export const ADMIN_ADD_CONVERSATIONS = `${ADMIN}conversations/add`;
export const ADMIN_EDIT_CONVERSATIONS_LINK = `${ADMIN}conversations/edit/`;
export const ADMIN_EDIT_CONVERSATIONS = `${ADMIN_EDIT_CONVERSATIONS_LINK}:conversationId`;

export const ADMIN_STATS = `${ADMIN}stats`;

export const ADMIN_DOMAIN_MOVEMENTS = `${ADMIN}domain-movements`;

export const ADMIN_USER_INSIGHTS = `${ADMIN}user-insights`;
export const ADMIN_ADD_USER_INSIGHTS = `${ADMIN}user-insights/add`;
export const ADMIN_EDIT_USER_INSIGHTS_LINK = `${ADMIN}user-insights/edit/`;
export const ADMIN_EDIT_USER_INSIGHTS = `${ADMIN_EDIT_USER_INSIGHTS_LINK}:insightTriggerId`;

export const USER_MAILBOXES = '/user/mailboxes';
export const USER_EDIT_MAILBOX_ID = `${ADMIN}user/:dataUserId/editmailbox/:mailboxId`;
export const USER_EDIT_MAILBOX = '/user/editmailbox/';

export const USER_INSIGHTS = '/user/insights';

export const USER_SENDING_PATTERN = '/user/sending-pattern/';
export const USER_SENDING_PATTERN_ID = `${USER_SENDING_PATTERN}:mailboxId`;

export const USER_CONTENT_TESTS = '/user/content-tests';
export const USER_CREATE_CONTENT_TESTS = '/user/create-content-test/';
export const USER_CREATE_CONTENT_TESTS_ID = `${USER_CREATE_CONTENT_TESTS}:contentId`;
export const USER_EDIT_CONTENT_TESTS = '/user/edit-content-test/';
export const USER_EDIT_CONTENT_TESTS_ID = `${ADMIN}user/:dataUserId/edit-content-test/:contentId`;

export const USER_SETTINGS = '/user/settings';
export const USER_TEAM = '/user/team';
export const USER_PROFILE = '/user/myprofile';
export const USER_SECURITY = '/user/security';
export const USER_SUBSCRIPTION = '/user/subscription';




