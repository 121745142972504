import { ADMIN_ADD_CONVERSATIONS, ADMIN_ADD_USER_INSIGHTS, ADMIN_CONVERSATIONS, ADMIN_DOMAIN_MOVEMENTS, ADMIN_EDIT_CONVERSATIONS, ADMIN_EDIT_CONVERSATIONS_LINK, ADMIN_EDIT_USER_INSIGHTS, ADMIN_EDIT_USER_INSIGHTS_LINK, ADMIN_STATS, ADMIN_USERS, ADMIN_USERS_DETAILS_ID, ADMIN_USER_INSIGHTS, USER_MAILBOXES, USER_SENDING_PATTERN } from '../Routes/Constants/RouteUrls';
import { AreaChartOutlined, BulbOutlined, DashboardOutlined, LeftOutlined, LogoutOutlined, PicLeftOutlined, UserOutlined } from '@ant-design/icons';
import { Image, Layout, Menu, Tooltip, Typography } from 'antd';
import { Link, useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Auth } from "aws-amplify";
import { getlocalStorageValue } from '../Helpers/globalValues'
import { scrollToTop } from '../Helpers/WindowHelper';

const SiderLayout = () => {
    const [openKeys, setOpenKeys] = useState([]);
    const { Text } = Typography;
    const [showSendingPattern, setShowSendingPattern] = useState(false);
    const { mailboxDomainLists } = useSelector((state) => state.mailboxDomainListsInfo);
    const { demoData, userId, companyId } = getlocalStorageValue(['demoData','userId','companyId'])
    const history = useHistory();
    const dispatch = useDispatch();
    const { SubMenu } = Menu;
    const sendingPattern = (type) => {
        if(type==='open'){
            setShowSendingPattern(true);
            setOpenKeys(['sub1'])
            setloader(locationKey)
        }else {
            setShowSendingPattern(false);
        }
    }

    const handleLogout = async () => {
        localStorage.clear();
        await Auth.signOut({ global: true });
        history.push("/");
    }
    const [loader, setloader] = useState('1');
    const location = useLocation();
    let locationKey='1';
    if(location){
        switch (location.pathname){
            case ADMIN_USERS:
            case ADMIN_USERS_DETAILS_ID:
                    locationKey ='11';
                    break;

            case ADMIN_STATS:
                    locationKey ='10';
                    break;

            case ADMIN_DOMAIN_MOVEMENTS:
                    locationKey ='14';
                    break;

            case ADMIN_USER_INSIGHTS:
            case ADMIN_ADD_USER_INSIGHTS:
            case location?.pathname?.includes(ADMIN_EDIT_USER_INSIGHTS_LINK):
                    locationKey ='15';
                    break;

            case ADMIN_CONVERSATIONS:
            case ADMIN_ADD_CONVERSATIONS:
            case ADMIN_EDIT_CONVERSATIONS:
                    locationKey ='12';
                    break;

            default:
                locationKey ='11';
                location?.pathname?.includes(ADMIN_EDIT_CONVERSATIONS_LINK) && (locationKey ='12');
                // do nothing
        }
        if(loader!==locationKey)
            setloader(locationKey)
    }

    useEffect(() => {
        
        // (!mailboxDomainLists && !mailboxDomainLists?.length)
        //     && dispatch(mailboxDomainListsAction(userId));

        location?.pathname?.includes(USER_SENDING_PATTERN) && sendingPattern('open')
    }, [])

   
    const planDetails = useSelector((state) => state.planDetails);
    const { planData, 
        /* loading, error */
    } = planDetails;
    const authResult = JSON.parse(localStorage.getItem('AuthenticationResult'));
    if(!userId){
        handleLogout();
    }
    const planPermission = JSON.parse(planData?.planData?.records[0]?.plan_policy ?? '{}');
    const contentTestsFlag = planPermission?.contentTests

    useEffect(() => {
        if(loader===5) {
            (!demoData || !contentTestsFlag) && history.push(USER_MAILBOXES);
        }
    }, [loader])
    // console.log(`loader`, loader)
    useEffect(() => { scrollToTop(); } ,[location.pathname]);

    const {Sider} = Layout;
    return (
        <Sider theme="white" className="h-screen fixed">
            <div className="logo ns-menu" style={{margin:"20px 25px 11px 14px"}}>
                <Image width={135} height={40}  preview={false} src="/images/logo_allegrow.svg"></Image>
            </div>
            <div className="static mt-20" >
                    {
                        !showSendingPattern
                        ?   <div className="">
                               <Menu  subMenuOpenDelay={2} subMenuCloseDelay={1} theme="white"  className="v-align-3 ns-menu-10 h-screen" mode={`${'vertical'}`} selectedKeys={loader}> 
                                    <Menu.Item key="11"  icon={<UserOutlined />}>
                                        <Link to={ADMIN_USERS} >Users</Link>
                                    </Menu.Item>
                                    <Menu.Item key="12"  icon={<PicLeftOutlined />}>
                                        <Link to={ADMIN_CONVERSATIONS} >Conversations</Link>
                                    </Menu.Item>
                                    <Menu.Item key="10"  icon={<AreaChartOutlined />}>
                                        <Link to={ADMIN_STATS} >Stats</Link>
                                    </Menu.Item>
                                    <Menu.Item key="14"  icon={<DashboardOutlined />}>
                                        <Link to={ADMIN_DOMAIN_MOVEMENTS} >Domain Movements</Link>
                                    </Menu.Item>
                                    <Menu.Item key="15"  icon={<BulbOutlined />}>
                                        <Link to={ADMIN_USER_INSIGHTS} >User Insights</Link>
                                    </Menu.Item>
                                    <Menu.Item key="4" className="ns-absolute bottom-0 w-full"   icon={<LogoutOutlined />}>
                                        <Link to="#" onClick={handleLogout}>Logout</Link>
                                    </Menu.Item> 
                               </Menu>
                            </div>
                        :
                            <Menu className="v-align-2 ns-menu-51" mode={`${'inline'}`} selectedKeys={loader} expandIcon={'  '}  subMenuOpenDelay={20} subMenuCloseDelay={10} openKeys={openKeys} theme="white">
                                <SubMenu className="ns-menu-51" onTitleClick={()=>sendingPattern('close')} icon={<LeftOutlined />} title="Sending Pattern"  key="sub1">
                                        {   
                                            !!(mailboxDomainLists && mailboxDomainLists?.length)
                                            ?  
                                                mailboxDomainLists?.map((domain) => {
                                                    return (
                                                        <Menu.ItemGroup className="ns-sub-menu" key={domain.domain_name} title={<Tooltip mouseEnterDelay={1}  placement="right" title={domain.domain_name}><Text className="text-gray-500 w-full"  ellipsis={true}>{domain.domain_name}</Text></Tooltip>}>
                                                            {domain.sub_accounts.map((emailAccount,emailIndex) =>  <Menu.Item className="domain-email-ellipsis" key={emailAccount.id}><Tooltip mouseEnterDelay={1} className=""  placement="left" title={emailAccount.email}><Link className="" to={USER_SENDING_PATTERN+emailAccount.id}><Text className="w-full text-gray-500"  ellipsis={true}><span className="text-black">{emailAccount.email.split('@')[0]}</span>@{emailAccount.email.split('@')[1]}</Text></Link></Tooltip></Menu.Item>)}
                                                        </Menu.ItemGroup>
                                                    )
                                                })
                                            : <Menu.ItemGroup className="ns-sub-menu" key={444} title={'No connections are available'}></Menu.ItemGroup>
                                        }
                                </SubMenu>
                            </Menu>
                    }          
                
            </div>
      </Sider>
    )
}

export default SiderLayout;