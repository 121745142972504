import { allInsightsReducer, archivedInsightsReducer, domainInsightsCountReducer, editInsightsReducer, mailboxInsightsReducer, saveInsightsReducer, userInsightsReducer } from './Reducers/insightsReducers';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { companyDetailsReducer, companyDetailsSaveReducer } from './Reducers/companyDetailsReducers';
import {
  concurrentContentTestsReducer,
  concurrentContentTestsSaveReducer,
  contentTestsCreateUpdateReducer,
  contentTestsDeleteReducer,
  contentTestsDraftReducer,
  contentTestsFinishReducer,
  contentTestsReducer
} from './Reducers/contentTestsReducers';
import { conversationDeleteReducer, conversationReducer, conversationSaveReducer } from './Reducers/conversationReducers';
import { getCurrentPlanReducer, planAllDetailsReducer, planDetailsReducer, userPlanSaveReducer } from './Reducers/planDetailsReducers';
import { getReputationDecreasesReportReducer, getReputationIncreasesReportReducer, processedEmailsReducer, spamRatesReducer } from './Reducers/analyticsReducers';
import { loadState, saveState } from './localStorage';
import {
  mailboxConnectReducer,
  mailboxDetailsUpdateReducer,
  mailboxDomainListsReducer,
  mailboxDomainStatisticsReducer,
  mailboxIdStatisticsReducer,
  mailboxListsLeaderboardReducer,
  mailboxValidateReducer
} from './Reducers/mailboxReducers';
import { outgoingMailboxDailyFutureLimitReducer, outgoingMailboxDailyFutureLimitSaveReducer, outgoingMailboxExpectedEmailsReducer } from './Reducers/outgoingMailboxReducers';
import {
  userRegisterReducer,
  userSigninReducer,
  userUpdateReducer,
} from './Reducers/userReducers';
import { usersDeleteReducer, usersListReducer } from './Reducers/usersListReducers';

import { throttle } from 'lodash';
import thunk from 'redux-thunk';
import { userMailboxRecommendReducer } from './Reducers/integrationReducers';

const reducer = combineReducers({
  userSignin: userSigninReducer,
  userRegister: userRegisterReducer,
  userUpdate: userUpdateReducer,
  
  mailboxIdStatistics: mailboxIdStatisticsReducer,
  mailboxDetailsUpdate:mailboxDetailsUpdateReducer,
  mailboxConnectInfo:mailboxConnectReducer,
  mailboxDomainListsInfo:mailboxDomainListsReducer,
  mailboxListsLeaderboardInfo:mailboxListsLeaderboardReducer,
  mailboxDomainStatisticsInfo:mailboxDomainStatisticsReducer,
  mailboxValidateInfo:mailboxValidateReducer,
  
  outgoingMailboxDailyFutureLimit:  outgoingMailboxDailyFutureLimitReducer,
  outgoingMailboxDailyFutureLimitSave:  outgoingMailboxDailyFutureLimitSaveReducer,
  outgoingMailboxExpectedEmails:  outgoingMailboxExpectedEmailsReducer,
  
  companyDetails:companyDetailsReducer,
  companyDetailsSave:companyDetailsSaveReducer,
  
  planDetails:planDetailsReducer,
  planAllDetails:planAllDetailsReducer,
  currentPlanInfo:getCurrentPlanReducer,
  userPlanSaveInfo:userPlanSaveReducer,
  
  userInsightsDetails:userInsightsReducer,
  mailboxInsightsDetails:mailboxInsightsReducer,
  archivedInsightsDetails:archivedInsightsReducer,
  domainInsightsCountDetails:domainInsightsCountReducer,
  allInsightsInfo:allInsightsReducer,
  editInsightsInfo:editInsightsReducer,
  saveInsightsInfo:saveInsightsReducer,

  contentTestsInfo:contentTestsReducer,
  contentTestsCreateUpdateInfo:contentTestsCreateUpdateReducer,
  contentTestsDraftInfo:contentTestsDraftReducer,
  contentTestsDeleteInfo:contentTestsDeleteReducer,
  contentTestsFinishInfo:contentTestsFinishReducer,
  concurrentContentTestsInfo:concurrentContentTestsReducer,
  concurrentContentTestsSaveInfo:concurrentContentTestsSaveReducer,
  
  usersListInfo:usersListReducer,

  conversationInfo:conversationReducer,
  conversationSaveInfo:conversationSaveReducer,
  conversationDeleteInfo:conversationDeleteReducer,

  spamRatesInfo:spamRatesReducer,
  processedEmailsInfo:processedEmailsReducer,
  reputationIncreasesReport:getReputationIncreasesReportReducer,
  reputationDecreasesReport:getReputationDecreasesReportReducer,

  userMailboxRecommendInfo:userMailboxRecommendReducer,
  usersDeleteAccountInfo:usersDeleteReducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedState = loadState();
const store = createStore(
  reducer,
  persistedState,
  composeEnhancer(applyMiddleware(thunk)),
);

store.subscribe(throttle(() => {
  saveState({
    planDetails: store.getState().planDetails
  });
}, 1000));

export default store;
